import React, { useEffect, useState } from "react"

import "../../css/studilive/pricing.css"

function PricingCards(props) {
  const [cardData, setCardData] = useState(null)
  const [index, setIndex] = useState(-1)

  useEffect(() => {
    if (props.data) {
      setCardData(props.data)
    }
  }, [props.data])

  const cardExpand = val => {
    if (index == val) {
      setIndex(-1)
    } else {
      setIndex(val)
    }
  }

  return (
    <div className="container-fluid">
      <div className="row" id="all-plans">
        {cardData &&
          cardData.availablePlan.map((item, i) => {
            i = i + 1
            let color
            let bgColor

            if (i == 3 || i % 3 == 0) {
              color = "rgb(208,140,232)"
              bgColor = "#EEDEF4"
            } else if (i == 2 || i % 2 == 0) {
              color = "#62A672"
              bgColor = "#62A672"
            } else {
              color = "#86CA66"
              bgColor = "#DDEDD5"
            }
            let temp_arr = []
            let timeFlag = 0

            //for splitting plan details in list
            if (item.planDetails) {
              let detailsList = item.planDetails
              let s_list = detailsList.split("<li>")

              s_list.forEach(i => {
                let regex = /(<([^>]+)>)/gi
                let result = i.replace(regex, "")
                temp_arr.push(result)
              })
            }

            return (
              <div className="col-lg-4 px-1 px-md-2 mb-3" key={i}>
                <div
                  className="plan-border"
                  style={{ borderRadius: "20px", border: `2px solid ${color}` }}
                >
                  <div
                    className="plan-name font-lato color-white py-3 text-center"
                    style={{ backgroundColor: color }}
                  >
                    {item.planName}
                  </div>
                  <div className="row discount-div">
                    <div className="col-12 mb-4">
                      {item.discount && item.discount !== "0" ? (
                        <div className="discount-bg">
                          <span className="discount-label font-samsung">
                            You save
                          </span>
                          <span className="discount-value font-samsung">
                            {item.discount}%
                          </span>
                        </div>
                      ) : (
                        <> </>
                      )}
                    </div>
                  </div>

                  <div
                    className="plan-price color-black text-center"
                    style={{ backgroundColor: bgColor, borderRadius: "20px" }}
                  >
                    Rs. {item.planPrice}
                  </div>
                  <div className="text-center mr-3">
                    <div
                      className="plan-details-head"
                      style={{ border: `1px solid ${color}` }}
                    >
                      <span>Plan Details</span>
                      <i
                        className={
                          index == i
                            ? "fa fa-angle-up color-white details-arrow"
                            : "fa fa-angle-down color-white details-arrow"
                        }
                        data-toggle="collapse"
                        href={"#collapse" + i}
                        aria-expanded="false"
                        aria-controls={"collapse" + i}
                        style={{ backgroundColor: color }}
                        onClick={() => cardExpand(i)}
                      />
                    </div>
                  </div>
                  <div className="plan-details"></div>
                  <div
                    className="collapse multi-collapse mx-3 plan-details font-samsung"
                    id={"collapse" + i}
                    data-parent="#all-plans"
                  >
                    <br />
                    {temp_arr.map(val => {
                      if (val == "Till the end of Academic Year\r\n") {
                        timeFlag = 1
                        return
                      }
                      return (
                        <div className="d-flex">
                          <i
                            className="fa fa-caret-right trangle-pp"
                            style={{ color: color }}
                            aria-hidden="true"
                          />
                          <span className="know-more-text font-lato">
                            {val}
                          </span>
                        </div>
                      )
                    })}
                    {timeFlag == 1 ? (
                      <span style={{ fontSize: "13px", color: "red" }}>
                        <i
                          className="fa fa-caret-right trangle-pp"
                          style={{ color: color, visibility: "hidden" }}
                          aria-hidden="true"
                        />
                        <i>*Till the end of Academic Year</i>
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="text-center mx-auto">
                    <a href={`https://liveapi.tatastudi.com/pg/${item.planId}`}>
                      <button
                        className="register-now"
                        disabled={!props.selectionsDone}
                      >
                        Buy Now
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default PricingCards
