import React, {useState, useEffect} from "react"
import SEO from "../../components/seo"
import Navbar from "../../components/StudiLive Comps/navbar"
import StudiLiveFooter from "../../components/StudiLive Comps/footer"

import "../../css/studilive/globalRules.css"
import "../../css/globals.css"
import StudiLiveMobileFooter from "../../components/StudiLive Comps/mobileFooter"
import MobileNavbar from "../../components/StudiLive Comps/mobileNavbar.js"
import Pricing from "../../components/StudiLive Comps/pricing"
import FormHome from "../../components/StudiLive Comps/formHome"
import useIsMobile from "../../hooks/useIsMobile"
import DatalayerEvents from "../../../services/datalayer"

export default function Index(){
  const isMobile = useIsMobile('1023px');

  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div>
      <SEO title="Courses - Studi Live | Tata Studi" lang="en" />
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <div>
        <div className="pageBanner-studilive paymentPage bannerText">
          <h1 className="displayNone">Courses</h1>
          <h2 className="banner_text_new"> Courses </h2>
        </div>
      </div>
      <Pricing mobileScreen={isMobile} />
      <FormHome />
      {isMobile ? <StudiLiveMobileFooter /> : <StudiLiveFooter />}
    </div>
  )
}
