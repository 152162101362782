import React, { useEffect, useState } from "react"
import { components } from "react-select"
import { default as ReactSelect } from "react-select"
import Select from "react-select"

import PricingCards from "./pricingCards"
import MobilePricingCards from "./mobilePricingCards"

export default function Pricing(props) {
  const [fetchedServiceData, setFetchedServiceData] = useState(null)
  const [courseList, setCourseList] = useState([])
  const [selectedCourse, setSelectedCourse] = useState(null)
  const [gradeList, setGradeList] = useState([])
  const [selectedGrade, setSelectedGrade] = useState(null)
  const [subjectList, setSubjectList] = useState([])
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedSubjectArr, setSelectedSubjectArr] = useState(null)
  const [selectedSubNames, setSelectedSubNames] = useState(null)
  const [pricingCardData, setPricingCardData] = useState(null)

  const [disableGrades, setDisableGrades] = useState(null)
  const [disableSubjects, setDisableSubjects] = useState(null)
  const [allSelectionsDone, setAllSelectionsDone] = useState(null)

  function Option(props) {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    )
  }

  useEffect(() => {
    fetch("https://liveapi.tatastudi.com/TCE/GetCourseInfo")
      .then(res => {
        if (res.ok) {
          return res.json()
        } else {
          alert("There was some error in loading the page! Please try again!")
        }
      })
      .then(result => {
        setFetchedServiceData(result)
        let tempArr = []
        result.courseList.forEach(item => {
          tempArr.push({ value: item.courseName, label: item.courseName })
        })
        setCourseList(tempArr)
      })
      .catch(err => console.log(err))
  }, [])

  const handleCourseChange = course => {
    setSelectedCourse(course.value)
    setSelectedGrade(null)
    setSelectedSubjectArr(null)
    setSelectedSubject(null)
    setSelectedSubNames(null)

    //TO CHANGE GRADES ACCORDING TO COURSE
    let courseArr = fetchedServiceData.courseList
    let newCourse = courseArr.find(item => item.courseName === course.value)

    let tempArr = []

    newCourse &&
      newCourse.gradeList.forEach(item => {
        tempArr.push({ value: item.gradeName, label: item.gradeName })
      })
    setGradeList(tempArr)
  }

  const handleGradeChange = grade => {
    setSelectedGrade(grade.value)
    setSelectedSubjectArr(null)
    setSelectedSubject(null)
    setSelectedSubNames(null)

    //TO CHANGE SUBJECTS ACCORDING TO COURSE AND GRADE
    let courseArr = fetchedServiceData.courseList
    let newCourse = courseArr.find(item => item.courseName === selectedCourse)

    let newGrade = newCourse.gradeList.find(
      item => item.gradeName === grade.value
    )
    let tempArr = []
    newGrade &&
      newGrade.subjectList.forEach(item => {
        tempArr.push({ value: item.subjectId, label: item.subjectName })
      })
    setSubjectList(tempArr)
  }

  const handleSubjectChange = subjects => {
    setSelectedSubject(subjects)
    makeSubjectArr(subjects)
  }

  const makeSubjectArr = subjects => {
    let tempArr = []
    let tempSubName = []
    subjects.forEach(item => {
      tempArr.push(item.value)
      tempSubName.push(item.label)
    })
    setSelectedSubjectArr(tempArr)
    setSelectedSubNames(tempSubName)
  }

  const requestPlans = () => {
    if (
      !selectedCourse ||
      !selectedGrade ||
      !selectedSubjectArr ||
      !selectedSubjectArr.length
    ) {
      return
    }
    var planHeaders = new Headers()
    planHeaders.append("Content-Type", "application/json")

    var reqSubjects = JSON.stringify(selectedSubjectArr)

    var requestOptions = {
      method: "POST",
      headers: planHeaders,
      body: reqSubjects,
      redirect: "follow",
    }

    fetch("https://liveapi.tatastudi.com/TCE/GetSubjectPlan", requestOptions)
      .then(response => response.json())
      .then(result => setPricingCardData(result))
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    if (!selectedCourse) {
      setDisableGrades(true)
    } else {
      setDisableGrades(false)
    }

    if (!selectedGrade) {
      setDisableSubjects(true)
    } else {
      setDisableSubjects(false)
    }

    if (
      selectedCourse &&
      selectedGrade &&
      selectedSubjectArr &&
      selectedSubjectArr.length
    ) {
      setAllSelectionsDone(true)
    } else {
      setAllSelectionsDone(false)
    }
  })

  useEffect(() => {
    requestPlans()
  }, [selectedSubjectArr])

  return (
    <div>
      <div className="container-fluid">
        <div className="row mx-1 grade-select">
          <div className="col-lg-4 mt-3">
            {courseList && (
              <Select
                options={courseList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={handleCourseChange}
                placeholder="Board"
              />
            )}
          </div>

          <div className="col-lg-4 mt-3">
            {gradeList && (
              <Select
                options={gradeList}
                closeMenuOnSelect={true}
                hideSelectedOptions={false}
                onChange={handleGradeChange}
                placeholder="Grade"
                isDisabled={disableGrades}
              />
            )}
          </div>

          <div className="col-lg-4 mt-3">
            {subjectList && (
              <ReactSelect
                placeholder="Subjects"
                options={subjectList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                onChange={handleSubjectChange}
                allowSelectAll={true}
                value={selectedSubject}
                isDisabled={disableSubjects}
              />
            )}
          </div>
        </div>

        <br />

        {selectedCourse && (
          <div className="text-center">
            <b>
              {" "}
              You've selected {selectedCourse} {selectedGrade}{" "}
              {selectedSubNames && selectedSubNames.join(", ")}
            </b>
          </div>
        )}

        {/* CARDS */}
        <br />

        {props.mobileScreen ? (
          <div style={{ marginTop: "30px" }}>
            <MobilePricingCards
              data={pricingCardData}
              selectionsDone={allSelectionsDone}
            />
          </div>
        ) : (
          <PricingCards
            data={pricingCardData}
            selectionsDone={allSelectionsDone}
          />
        )}
      </div>
    </div>
  )
}
